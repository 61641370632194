// Here you can add other styles
/*Color Codes*/
/*Shadow Codes*/
/*Fonts*/
/*Screen Size*/
/*Animation*/
/* Media query Manager */
/*
0 - 600px       :   Phone
601 - 900px     :   Tablet Potrait
901 - 1200px    :   Tablet Landscape
1201 - 1800px   :   Desktop
1801 +          :   BigDesktop
*/
/*Break-point argument choices:
- mob
- tab-p
- tab-l
- b-desktop
*/
// @import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,900,700,500,300,100);
@import "https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200&display=swap";
// @import url('https://fonts.cdnfonts.com/css/merchant-copy-doublesize');

body {
    background: #838389;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, 'Nunito', Helvetica, Arial, sans-serif;
    font-size: 11pt;
}

:root {
    iframe#webpack-dev-server-client-overlay {
        display: none !important
    }
}

:root {
    --cui-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --cui-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --cui-card-cap-padding-y: 0.5rem;
    --cui-card-cap-padding-x: 1rem;
    --cui-card-border-width: 1px;
    --cui-card-border-color: rgba(0,0,21,.125);
    --cui-card-cap-bg: rgba(0,0,21,.03);
}

.form-control:disabled, .form-control[readonly] {
    background-color: #d8dbe0;
    background-color: var(--cui-input-disabled-bg,#d8dbe0);
    border-color: #b1b7c1;
    border-color: var(--cui-input-disabled-border-color,#b1b7c1);
    opacity: 1;
}

.with_logo {
    background-image: url('../assets/brand/bg-logo.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.reg_pelanggan {
    position: absolute;
    top: 40mm;
    left: 30mm;
    right: 10mm;
}

.card.disabled {
    background-color: #e9ecef;
    opacity: 0.7;
    z-index: 20;
}


.toggle,
[id^=drop] {
    display: none;
}

/* Giving a background-color to the nav container. */


.foto_tambah_pelanggan {
    padding-top: 38% !important;
    padding-top: 38%;
    background-position: 50%;
    border-radius: 10px;
    background-color: rgb(0, 0, 0, 0.65);
}

.foto_tambah_mitra {
    padding-top: 38% !important;
    padding-top: 38%;
    background-position: 50%;

}

.pie-chart {
    margin: 20px;
    text-align: center;

    &>svg {
        &>path {
            stroke: var(--color1);
            stroke-width: 1px;
        }

        &>a {
            transition: opacity var(--fade);
            opacity: 0;

            &>text {
                font-weight: bold;
                opacity: 0.4;
            }

            &>path {
                stroke: var(--color4);
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    &>.legend {
        text-align: left;
        margin: 15px;

        &>.legend-line {
            &>.color {
                height: 12px;
                width: 12px;
                margin-right: 5px;
                border: 1px solid var(--color1);
                display: inline-block;
            }
        }
    }
}

.dropdown-menu {
    background-clip: padding-box;
    background-color: #fff;
    background-color: var(--cui-dropdown-bg,#fff);
    border: 1px solid rgba(0,0,21,.15);
    border: 1px solid var(--cui-dropdown-border-color,rgba(0,0,21,.15));
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0,0,21,.15);
    color: rgba(44,56,74,.95);
    color: var(--cui-dropdown-color,rgba(44,56,74,.95));
    display: none;
    font-size: 1rem;
    list-style: none;
    margin: 0;
    min-width: 10rem;
    padding: 0.5rem 0;
    position: absolute;
    text-align: start;
    z-index: 1000;
}

/* Media Queries
--------------------------------------------- */
@media all and (max-width: 768px) {
    .logo {
        display: block;
        padding: 0;
        width: 100%;
        text-align: center;
        float: none;
    }

    nav {
        margin: 0;
    }

    /* Hide the navigation menu by default */
    /* Also hide the  */
    .toggle+a,
    .menu {
        display: none;
    }

    /* Stylinf the toggle lable */
    .toggle {
        display: block;
        background-color: #ffffff;
        padding: 14px 20px;
        color: #1A1919;
        font-size: 17px;
        text-decoration: none;
        border: none;
    }

    .toggle:hover {
        background-color: #badefc;
    }

    /* Display Dropdown when clicked on Parent Lable */
    [id^=drop]:checked+ul {
        display: block;
        width: -webkit-fill-available;
    }

    /* Change menu item's width to 100% */
    nav ul li {
        display: block;
        width: 100%;
    }

    nav ul ul .toggle,
    nav ul ul a {
        padding: 0 40px;
    }

    nav ul ul ul a {
        padding: 0 80px;
    }

    nav a:hover,
    nav ul ul ul a {
        background-color: #badefc;
    }

    nav ul li ul li .toggle,
    nav ul ul a,
    nav ul ul ul a {
        padding: 14px 20px;
        color: #1A1919;
        font-size: 17px;
    }

    /* Hide Dropdowns by Default */
    nav ul ul {
        float: none;
        position: static;
        color: #1A1919;
        /* has to be the same number as the "line-height" of "nav a" */
    }

    /* Hide menus on hover */
    nav ul ul li:hover>ul,
    nav ul li:hover>ul {
        display: none;
    }

    /* Fisrt Tier Dropdown */
    nav ul ul li {
        display: block;
        width: 100%;
    }

    nav ul ul ul li {
        position: static;
        /* has to be the same number as the "width" of "nav ul ul li" */
    }
}

@media all and (max-width: 330px) {
    nav ul li {
        display: block;
        width: 94%;
    }
}

.dropdown a {
    color: #1A1919;
}

.dropdown a:hover {
    color: #1A1919;
}

/* Dropdown */
.dropdown {
    display: inline-block;
    position: relative;
}

.dd-button {
    display: inline-block;
    background-color: #badefc;
    outline: 1px solid #a5c3e4;
    outline-offset: -1px;
    color: #1A1919;
    cursor: pointer;
    white-space: nowrap;
    padding: 14px 20px;
}

.dd-button span {
    padding: 0 16px 0 0;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.dd-button:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0.7rem;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #1A1919;
}

.dd-input {
    display: none;
}

.dd-menu {
    position: absolute;
    top: 100%;
    border-radius: 4px;
    padding: 0;
    margin: 4px 0 0 0;
    box-shadow: 2px 5px 12px 0px rgba(14, 97, 185, 0.1);
    background-color: #ffffff;
    list-style-type: none;
    z-index: 99;
    right: 0;
}

.dd-input+.dd-menu {
    display: none;
}

.dd-input:checked+.dd-menu {
    display: block;
}

.dd-menu li {
    padding: 0.8rem 1.5rem;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 400;
}

.dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
}

.dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #a5c3e4;
}

/* apply a natural box layout model to all elements, but allowing components to change */
*,
::after,
::before {
    box-sizing: border-box;
}



a {
    color: #0e61b9;
    text-decoration: none;
    transition: color 0.1s linear;
}

.preloader-container {
    position: relative;
  }

  tbody.ct-centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .preloader-ct {
    border: 8px solid #3498db;
    border-top: 8px solid #f0f0f0;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
  }




.projectname-container {
    font-size: 15px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1400px) {
    .projectname-container {
        max-width: 1360px;
    }
}

@media (max-width: 1399px) and (min-width: 1200px) {
    .projectname-container {
        max-width: 1140px;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .projectname-container {
        max-width: 960px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .projectname-container {
        max-width: 720px;
    }
}

@media (max-width: 767px) and (min-width: 576px) {
    .projectname-container {
        max-width: 540px;
    }
}

.projectname-breadcrumb--section {
    background-color: #f9f9f9;
}

.projectname-page-header .breadcrumbs ol {
    display: inline-flex;
}

.projectname-page-header .breadcrumbs ol .breadcrumb {
    margin: 1rem 0.5rem;
}

.projectname-page-header .breadcrumbs ol .breadcrumb::after {
    margin-left: 0.5rem;
    content: "/";
}

.projectname-page-header .breadcrumbs ol .breadcrumb:last-child {
    pointer-events: none;
    cursor: default;
}

.projectname-page-header .breadcrumbs ol .breadcrumb:last-child a {
    color: #1A1919;
}

.projectname-page-header .breadcrumbs ol .breadcrumb:last-child a:hover {
    pointer-events: none;
    cursor: default;
}

.projectname-page-header .breadcrumbs ol .breadcrumb:last-child::after {
    content: "";
}

.projectname-form-container {
    padding: 2rem 0rem;
}

.projectname-right--section,
.projectname-model {
    background: #ffffff;
    border-radius: 6px;
    border: 0px solid #a5c3e4;
    box-shadow: 2px 5px 12px 0px rgba(14, 97, 185, 0.1);
}

.projectname-right--section .form--header,
.projectname-model .form--header {
    padding: 1.5rem 3rem;
}

.projectname-right--section .form--header_heading,
.projectname-model .form--header_heading {
    align-items: center;
}

.projectname-right--section .form--header_heading h2,
.projectname-model .form--header_heading h2 {
    font-family: "Mulish", sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 40px;
}

.projectname-right--section .form--header_heading-description,
.projectname-model .form--header_heading-description {
    color: #767676;
}

.projectname-right--section .form--header_sub-heading,
.projectname-model .form--header_sub-heading {
    font-size: 1.2rem;
    margin: 1rem 0;
    margin-top: 1.5rem;
}

.projectname-right--section .form--body,
.projectname-model .form--body {
    padding: 0 3rem;
}

.projectname-right--section .form--footer,
.projectname-model .form--footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
    border-top: 1px solid #DFE0E2;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: flex-end;
    padding: 1.5rem 3rem;
}

.projectname-right--section .form--footer .slds-button_outline-brand,
.projectname-model .form--footer .slds-button_outline-brand {
    padding: 0.3rem 1.5rem;
    background: transparent;
    color: #0e61b9;
    border: 0px;
    box-shadow: none;
}

.projectname-right--section .form--footer .slds-button_outline-brand,
.projectname-right--section .form--footer .slds-button_outline-brand:link,
.projectname-right--section .form--footer .slds-button_outline-brand:visited,
.projectname-model .form--footer .slds-button_outline-brand,
.projectname-model .form--footer .slds-button_outline-brand:link,
.projectname-model .form--footer .slds-button_outline-brand:visited {
    align-items: center;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    min-width: 88px;
    outline: 0;
    text-decoration: none;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
    position: relative;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    will-change: box-shadow;
}

.projectname-right--section .form--footer .slds-button_outline-brand:hover,
.projectname-model .form--footer .slds-button_outline-brand:hover {
    background: #215FAB;
    border: 1px solid #0e61b9;
    color: #ffffff;
}

.projectname-right--section .form--footer .slds-button_outline-brand:hover,
.projectname-model .form--footer .slds-button_outline-brand:hover {
    background: rgba(14, 97, 185, 0.1);
    color: #0e61b9;
    border: 0px;
    box-shadow: none;
}

.projectname-right--section .form--footer .slds-button_neutral,
.projectname-model .form--footer .slds-button_neutral {
    padding: 0.3rem 1.5rem;
    background: transparent;
    color: #6C6E75;
    border: 0px;
    box-shadow: none;
}

.projectname-right--section .form--footer .slds-button_neutral,
.projectname-right--section .form--footer .slds-button_neutral:link,
.projectname-right--section .form--footer .slds-button_neutral:visited,
.projectname-model .form--footer .slds-button_neutral,
.projectname-model .form--footer .slds-button_neutral:link,
.projectname-model .form--footer .slds-button_neutral:visited {
    align-items: center;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    min-width: 88px;
    outline: 0;
    text-decoration: none;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
    position: relative;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    will-change: box-shadow;
}

.projectname-right--section .form--footer .slds-button_neutral:hover,
.projectname-model .form--footer .slds-button_neutral:hover {
    background: #215FAB;
    border: 1px solid #0e61b9;
    color: #ffffff;
}

.projectname-right--section .form--footer .slds-button_neutral:hover,
.projectname-model .form--footer .slds-button_neutral:hover {
    background: rgba(108, 110, 117, 0.1);
    color: #6C6E75;
    border: 0px;
    box-shadow: none;
}

.projectname-right--section .form--footer .slds-button.slds-button_brand,
.projectname-model .form--footer .slds-button.slds-button_brand {
    padding: 0.3rem 1.5rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background: #0e61b9;
    border: 1px solid #0e61b9;
    color: #ffffff;
}

.projectname-right--section .form--footer .slds-button.slds-button_brand,
.projectname-right--section .form--footer .slds-button.slds-button_brand:link,
.projectname-right--section .form--footer .slds-button.slds-button_brand:visited,
.projectname-model .form--footer .slds-button.slds-button_brand,
.projectname-model .form--footer .slds-button.slds-button_brand:link,
.projectname-model .form--footer .slds-button.slds-button_brand:visited {
    align-items: center;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    min-width: 88px;
    outline: 0;
    text-decoration: none;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
    position: relative;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    will-change: box-shadow;
}

.projectname-right--section .form--footer .slds-button.slds-button_brand:hover,
.projectname-model .form--footer .slds-button.slds-button_brand:hover {
    background: #215FAB;
    border: 1px solid #0e61b9;
    color: #ffffff;
}

.projectname-right--section .form--footer .slds-button.slds-button_brand:active,
.projectname-right--section .form--footer .slds-button.slds-button_brand:focus,
.projectname-model .form--footer .slds-button.slds-button_brand:active,
.projectname-model .form--footer .slds-button.slds-button_brand:focus {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.projectname-right--section .form--footer .slds-button_destructive,
.projectname-model .form--footer .slds-button_destructive {
    padding: 0.3rem 1.5rem;
    background: transparent;
    color: #E71D36;
    border: 0px;
    box-shadow: none;
}

.projectname-right--section .form--footer .slds-button_destructive,
.projectname-right--section .form--footer .slds-button_destructive:link,
.projectname-right--section .form--footer .slds-button_destructive:visited,
.projectname-model .form--footer .slds-button_destructive,
.projectname-model .form--footer .slds-button_destructive:link,
.projectname-model .form--footer .slds-button_destructive:visited {
    align-items: center;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    min-width: 88px;
    outline: 0;
    text-decoration: none;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
    position: relative;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    will-change: box-shadow;
}

.projectname-right--section .form--footer .slds-button_destructive:hover,
.projectname-model .form--footer .slds-button_destructive:hover {
    background: #215FAB;
    border: 1px solid #0e61b9;
    color: #ffffff;
}

.projectname-right--section .form--footer .slds-button_destructive:hover,
.projectname-model .form--footer .slds-button_destructive:hover {
    background: rgba(231, 29, 54, 0.1);
    color: #E71D36;
    border: 0px;
    box-shadow: none;
}

.projectname-right--section .form--footer .slds-button_success,
.projectname-model .form--footer .slds-button_success {
    padding: 0.3rem 1.5rem;
    background: transparent;
    color: #90be6d;
    border: 0px;
    box-shadow: none;
}

.projectname-right--section .form--footer .slds-button_success,
.projectname-right--section .form--footer .slds-button_success:link,
.projectname-right--section .form--footer .slds-button_success:visited,
.projectname-model .form--footer .slds-button_success,
.projectname-model .form--footer .slds-button_success:link,
.projectname-model .form--footer .slds-button_success:visited {
    align-items: center;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    min-width: 88px;
    outline: 0;
    text-decoration: none;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
    position: relative;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    will-change: box-shadow;
}

.projectname-right--section .form--footer .slds-button_success:hover,
.projectname-model .form--footer .slds-button_success:hover {
    background: #215FAB;
    border: 1px solid #0e61b9;
    color: #ffffff;
}

.projectname-right--section .form--footer .slds-button_success:hover,
.projectname-model .form--footer .slds-button_success:hover {
    background: rgba(144, 190, 109, 0.1);
    color: #90be6d;
    border: 0px;
    box-shadow: none;
}

.projectname-right--section .yn_question,
.projectname-model .yn_question {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
}

.projectname-right--section .yn_question .question,
.projectname-model .yn_question .question {
    color: #333333;
    font-family: HelveticaNeue-Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
}

.projectname-right--section .yn_question .question .sub_section,
.projectname-model .yn_question .question .sub_section {
    color: #333333;
    font-family: HelveticaNeue;
    font-size: 12px;
}

.projectname-right--section .yn_question .anwser .projectname-radio-buttons,
.projectname-model .yn_question .anwser .projectname-radio-buttons {
    margin-top: 0rem;
}

.projectname-right--section .yn_question .anwser .projectname-radio-buttons .slds-radio label,
.projectname-model .yn_question .anwser .projectname-radio-buttons .slds-radio label {
    padding: 1.25rem 2.25rem 1.25rem 2.25rem;
}

.projectname-right--section:not(:first-child) {
    margin-top: 2rem;
}

.projectname-model {
    margin: auto;
}

.projectname-model--lg {
    width: 90%;
}

.projectname-model--md {
    width: 60%;
}

.projectname-model--sm {
    width: 35%;
}

.projectname-button--section {
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: flex-end;
    padding: 2rem 0;
}

.projectname-button--section .slds-button_outline-brand {
    padding: 0.3rem 1.5rem;
    background: transparent;
    color: #0e61b9;
    border: 0px;
    box-shadow: none;
}

.projectname-button--section .slds-button_outline-brand,
.projectname-button--section .slds-button_outline-brand:link,
.projectname-button--section .slds-button_outline-brand:visited {
    align-items: center;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    min-width: 88px;
    outline: 0;
    text-decoration: none;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
    position: relative;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    will-change: box-shadow;
}

.projectname-button--section .slds-button_outline-brand:hover {
    background: #215FAB;
    border: 1px solid #0e61b9;
    color: #ffffff;
}

.projectname-button--section .slds-button_outline-brand:hover {
    background: rgba(14, 97, 185, 0.1);
    color: #0e61b9;
    border: 0px;
    box-shadow: none;
}

.projectname-button--section .slds-button_neutral {
    padding: 0.3rem 1.5rem;
    background: transparent;
    color: #6C6E75;
    border: 0px;
    box-shadow: none;
}

.projectname-button--section .slds-button_neutral,
.projectname-button--section .slds-button_neutral:link,
.projectname-button--section .slds-button_neutral:visited {
    align-items: center;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    min-width: 88px;
    outline: 0;
    text-decoration: none;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
    position: relative;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    will-change: box-shadow;
}

.projectname-button--section .slds-button_neutral:hover {
    background: #215FAB;
    border: 1px solid #0e61b9;
    color: #ffffff;
}

.projectname-button--section .slds-button_neutral:hover {
    background: rgba(108, 110, 117, 0.1);
    color: #6C6E75;
    border: 0px;
    box-shadow: none;
}

.projectname-button--section .slds-button.slds-button_brand {
    padding: 0.3rem 1.5rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background: #0e61b9;
    border: 1px solid #0e61b9;
    color: #ffffff;
}

.projectname-button--section .slds-button.slds-button_brand,
.projectname-button--section .slds-button.slds-button_brand:link,
.projectname-button--section .slds-button.slds-button_brand:visited {
    align-items: center;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    min-width: 88px;
    outline: 0;
    text-decoration: none;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
    position: relative;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    will-change: box-shadow;
}

.projectname-button--section .slds-button.slds-button_brand:hover {
    background: #215FAB;
    border: 1px solid #0e61b9;
    color: #ffffff;
}

.projectname-button--section .slds-button.slds-button_brand:active,
.projectname-button--section .slds-button.slds-button_brand:focus {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.projectname-button--section .slds-button_destructive {
    padding: 0.3rem 1.5rem;
    background: transparent;
    color: #E71D36;
    border: 0px;
    box-shadow: none;
}

.projectname-button--section .slds-button_destructive,
.projectname-button--section .slds-button_destructive:link,
.projectname-button--section .slds-button_destructive:visited {
    align-items: center;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    min-width: 88px;
    outline: 0;
    text-decoration: none;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
    position: relative;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    will-change: box-shadow;
}

.projectname-button--section .slds-button_destructive:hover {
    background: #215FAB;
    border: 1px solid #0e61b9;
    color: #ffffff;
}

.projectname-button--section .slds-button_destructive:hover {
    background: rgba(231, 29, 54, 0.1);
    color: #E71D36;
    border: 0px;
    box-shadow: none;
}

.projectname-button--section .slds-button_success {
    padding: 0.3rem 1.5rem;
    background: transparent;
    color: #90be6d;
    border: 0px;
    box-shadow: none;
}

.projectname-button--section .slds-button_success,
.projectname-button--section .slds-button_success:link,
.projectname-button--section .slds-button_success:visited {
    align-items: center;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    min-width: 88px;
    outline: 0;
    text-decoration: none;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
    position: relative;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    will-change: box-shadow;
}

.projectname-button--section .slds-button_success:hover {
    background: #215FAB;
    border: 1px solid #0e61b9;
    color: #ffffff;
}

.projectname-button--section .slds-button_success:hover {
    background: rgba(144, 190, 109, 0.1);
    color: #90be6d;
    border: 0px;
    box-shadow: none;
}

.projectname-radio-buttons {
    margin-top: 1rem;
}

.projectname-radio-buttons .slds-radio {
    padding: 0;
}

.projectname-radio-buttons .slds-radio label {
    display: flex;
    align-items: center;
    padding: 0.25rem 1.25rem 0.25rem 1.25rem;
    border: 1px solid #ddd;
    border-radius: 3px;
    min-height: 2.5rem;
}

.projectname-radio-buttons .slds-radio label .slds-form-element__label {
    padding-right: 0;
}

@media (min-width: 768px) {
    .projectname-radio-buttons .slds-radio+.slds-radio {
        margin-left: 1rem;
    }
}

@media (min-width: 768px) {
    .projectname-radio-buttons.btns-x {
        display: flex;
    }
}

.projectname-vertical-wizard {
    border-right: 1px solid #DFE0E2;
}

.projectname-vertical-wizard ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.projectname-vertical-wizard li {
    display: block;
    position: relative;
}

.projectname-vertical-wizard li.complete .ico {
    left: 8px;
}

.projectname-vertical-wizard li.complete a::before {
    background-color: #90be6d;
    opacity: 1;
    height: calc(100% - 25px);
    bottom: -9px;
}

.projectname-vertical-wizard li.complete a::after {
    display: none;
}

.projectname-vertical-wizard li.locked>a:after {
    display: none;
}

.projectname-vertical-wizard li.current {
    background-color: #f4f4f4;
}

.projectname-vertical-wizard li.current a:before {
    background-color: #0e61b9;
    opacity: 1;
}

.projectname-vertical-wizard li.current a:after {
    border-color: #0e61b9;
    background-color: #0e61b9;
    opacity: 1;
}

.projectname-vertical-wizard li.current:before,
.projectname-vertical-wizard li.current:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.projectname-vertical-wizard li:last-child>a:before {
    display: none;
}

.projectname-vertical-wizard a {
    display: block;
    padding: 10px 10px 20px 40px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.35px;
    color: #1A1919;
}

.projectname-vertical-wizard a::before {
    content: "";
    position: absolute;
    width: 1px;
    height: calc(100% - 25px);
    background-color: #aaacb1;
    left: 13px;
    bottom: -9px;
    z-index: 3;
}

.projectname-vertical-wizard a::after {
    content: "";
    position: absolute;
    border: 2px solid #aaacb1;
    border-radius: 50%;
    top: 14px;
    left: 6px;
    width: 16px;
    height: 16px;
    z-index: 3;
}

.projectname-vertical-wizard a:hover,
.projectname-vertical-wizard a:focus {
    text-decoration: none;
}

.projectname-vertical-wizard .ico {
    pointer-events: none;
    font-size: 14px;
    position: absolute;
    left: 10px;
    top: 15px;
    z-index: 2;
}

.projectname-vertical-wizard .ico.ico-green {
    color: #90be6d;
}

.projectname-vertical-wizard .ico.ico-muted {
    color: #aaacb1;
}

.projectname-vertical-wizard .desc {
    display: block;
    line-height: 1.8;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #aaacb1;
}

.projectname-vertical-wizard ul>li.current:after {
    border-color: rgba(0, 0, 0, 0);
    border-left-color: #f4f4f4;
    border-width: 10px;
    margin-top: -10px;
}

.projectname-vertical-wizard ul>li.current:before {
    border-color: rgba(14, 97, 185, 0);
    border-left-color: #DFE0E2;
    border-width: 11px;
    margin-top: -11px;
}

label.slds-form-element__label {
    font-family: Muli;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.projectname-container .slds-tabs_scoped__content {
    padding: 0rem;
}

.projectname-container .slds-tabs_scoped__nav {
    border: 0px;
    border-radius: 0px 6px 0px 0px;
    background-color: #e8e8e8;
}

.projectname-container .slds-tabs_scoped__content {
    border: 0px;
    border-radius: 0px 0px 6px 6px;
}

.projectname-container .slds-is-active {
    border-top: 3px solid #1A1919;
}

.projectname-container .slds-is-active .slds-tabs_scoped__link {
    border: 0px;
}

.projectname-container .slds-tabs_scoped__link {
    padding: 0 2rem;
}

.form-label.sm {
    font-size: 0.875rem;
    margin-bottom: 0.3rem;
}

.block-panel {
    display: flex;
    justify-items: center;
    flex-direction: column;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 1600;
    opacity: 0.5;
}

.z-10 {
    z-index: 10;
}



.block-panel p {
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: white;
}

.block-panel-p {
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    /* opacity: 1; */
    z-index: 1700;
    position: absolute;
    color: white;
}

/*****************************************
  upload button styles
******************************************/
.file-upload {
    position: relative;
    display: inline-block;
}

.file-upload__label {
    color: #fff;
    border-radius: .4em;
    transition: background .3s;

    &:hover {
        cursor: pointer;
    }
}

.file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width: 0;
    height: 100%;
    opacity: 0;
}

.bg-image {
    padding-top: 50%;
    width: 100%;
    cursor: pointer;
    background-position: 50%;
}

.bg-image-cover {
    text-decoration: none;
    color: rgba(0, 0, 0, .87);
    display: block;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all .2s cubic-bezier(.4, 0, .2, 1);
}

.bg-image:hover {
    transform: scale(1.2);
    // box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
    // -webkit-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
    // -moz-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.foto_avatar {
    padding-top: 140% !important;
}

.react-datepicker__input-container input {
    width: 100%;
}

.footer {
    overflow: hidden;
    margin-top: 5mm;
    // border-radius: 7px;
    width: 100%;
    background: #303c54;
    color: white;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    border-left: 1px solid;
}

.example-content-main {

    width: 11rem;

    @include media-breakpoint-up(sm) {
        // @include make-col(9);
        width: 200px;
    }

    @include media-breakpoint-up(lg) {
        width: 500px;
    }


}

.main-filter-table {

    // width: 20rem;
    flex-wrap: nowrap;

    @include media-breakpoint-up(sm) {
        // @include make-col(9);
        width: 200px;
    }

    @include media-breakpoint-up(lg) {
        width: 500px;
    }


}

.main-search-button {

    display: flex;

    @include media-breakpoint-up(sm) {
        // @include make-col(9);
        display: none;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }


}


// for image smoth
.smooth-image {
    transition: opacity 1s;
}

.image-visible {
    opacity: 1;
}

.image-hidden {
    opacity: 0;
}

.smooth-no-image {
    background-image: linear-gradient(90deg, #ccc, #999, #ccc);
    color: #fff;
}

img[alt="img-logo"] {
    // border: 4px solid white;
    border-radius: 10px;
}

.label-primary {
    background-color: $primary;
}

.label-danger {
    background-color: $danger;
}

.label-warning {
    background-color: $warning;
}

.label-info {
    background-color: $info;
}

.label-light {
    background-color: $light;
}

.label-success {
    background-color: $success;
}

.label {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
}

.form-check-input:checked {
    border-color: #c3ddd1;
}

.sidebar {
    // background-color: #274546;
    background-color: $primary-sidebar;
}

.ui.horizontal.divider {
    display: table;
    white-space: nowrap;
    height: auto;
    margin: '';
    line-height: 1;
    text-align: center;
}

h4.ui.headers {
    font-size: 1.07142857rem;
}

.ui.headers {
    border: none;
    margin: calc(2rem - 0.14285714em) 0 1rem;
    padding: 0 0;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 1.28571429em;
    text-transform: none;
    color: rgba(0, 0, 0, .87);
}

.ui.horizontal.divider:after,
.ui.horizontal.divider:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC);
}

.ui.horizontal.divider:before {
    background-position: right 1em top 50%;
}

.ui.horizontal.divider:after,
.ui.horizontal.divider:before {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    background-repeat: no-repeat;
}

.ui.header .icon:only-child {
    display: inline-block;
    padding: 0;
    margin-right: 0.75rem;
}

.ui.header>.icon {
    display: table-cell;
    opacity: 1;
    font-size: 1.5em;
    padding-top: 0;
    vertical-align: middle;
}

.ui.divider>.icon {
    margin: 0;
    font-size: 1rem;
    height: 1em;
    vertical-align: middle;
}

i.icon,
i.icons {
    font-size: 1em;
}

i.icon {
    display: inline-block;
    opacity: 1;
    margin: 0 0.25rem 0 0;
    width: 1.18em;
    height: 1em;
    font-family: Icons;
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    text-align: center;
    // speak: none;
    // font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.rdt_Table {
    padding: 10px;
}

/*****************************************
 design styles
******************************************/

.page-multiple.A4 {
    width: 270mm;
}

// .page-multiple.A4 {
//     width: 270mm;
// }

#print_target .page-multiple.A58.portrait {
    width: 80mm;
    font-size: 8pt !important;
    line-height: 10pt;
}

.simplebar-init {
    max-height: 36rem;
}

@include media-breakpoint-up(sm) {
    .simplebar-init {
        max-height: 36rem;
    }
}

.accordion-body {
    padding: 10px;
}


.receipt {
    padding: 10px;
    /* font-family: 'Merchant Copy Doublesize', sans-serif; */
    /* border: 1px solid; */
    /* font-size: 8pt; */
    font-size: 16px;
}

.receipt img {
    width: 27.9px;
}

.garis {
    color: black;
    border: 1px dashed black;
    height: 1px;
    margin-top: 5px;
}

.garis-solid {
    color: black;
    border: 1px solid black;
    height: 2px;
    margin-top: 5px;
}

.fs-8pt {
    font-size: 12pt;
}

.receipt hr {
    opacity: unset !important;
}

.mt-3px {
    margin-top: 3px;
}

.fs-5pt {
    font-size: 5pt;
}

.fs-10pt {
    font-size: 12pt;
}

.fs-12pt {
    font-size: 14pt;
}

.fs-11pt {
    font-size: 11pt;
}

.w-14rem {
    width: 7rem;
    vertical-align: top;
}

.nama {
    overflow: hidden;
    /* max-width: 100px; */
    /* max-height: 15px; */
}

/* .nama::before {
   content: '..'
  } */

.float-right {
    float: right;
}

.text-small {
    font-size: 10px;
    line-height: inherit;
}

.receipt table tr td {
    vertical-align: top;
}

table.invoice-table {
    page-break-after: always;
}

table.invoice-table tr td {
    vertical-align: top;
}

.cm-1 {
    padding: 1cm;
}

.border-invoice {
    border: 1px solid;
}

@media print {
    .border {
        border: unset !important;
    }

    .border-invoice {
        border: unset !important;
    }

    .cm-1 {
        padding: unset !important;
    }
}

.modal-open {
    overflow: hidden;
}

.tags {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 5px;
}

.tags .tag {
    margin-bottom: 0.1rem
}

.tags .tag:not(:last-child) {
    margin-right: .5rem
}

.tags:last-child {
    /* margin-bottom: -.5rem */
}

.tags:not(:last-child) {
    margin-bottom: 1rem
}

.tags.are-medium .tag:not(.is-normal):not(.is-large) {
    font-size: 1rem
}

.tags.are-large .tag:not(.is-normal):not(.is-medium) {
    font-size: 1.25rem
}

.tags.is-centered {
    justify-content: center
}

.tags.is-centered .tag {
    margin-right: .25rem;
    margin-left: .25rem
}

.tags.is-right {
    justify-content: flex-end
}

.tags.is-right .tag:not(:first-child) {
    margin-left: .5rem
}

.tags.is-right .tag:not(:last-child) {
    margin-right: 0
}

.tags.has-addons .tag {
    margin-right: 0
}

.tags.has-addons .tag:not(:first-child) {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.tags.has-addons .tag:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.tag:not(body) {
    align-items: center;
    background-color: #f5f5f5;
    border-radius: .375em;
    color: #4a4a4a;
    display: inline-flex;
    font-size: .75rem;
    height: 2em;
    justify-content: center;
    line-height: 1.5;
    padding-left: .75em;
    padding-right: .75em;
    white-space: nowrap
}

.tag:not(body) .delete {
    margin-left: .25rem;
    margin-right: -.375rem
}

.tag:not(body).is-white {
    background-color: #fff;
    color: #0a0a0a
}

.tag:not(body).is-black {
    background-color: #0a0a0a;
    color: #fff
}

.tag:not(body).is-light {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, .7)
}

.tag:not(body).is-dark {
    background-color: #363636;
    color: #fff
}

.tag:not(body).is-primary {
    --cui-bg-opacity: 1;
    background-color: rgba(var(--cui-primary-rgb), var(--cui-bg-opacity)) !important;
    color: #fff
}
.tag:not(body).is-success {
    --cui-bg-opacity: 1;
    background-color: rgba(var(--cui-success-rgb), var(--cui-bg-opacity)) !important;
    color: #fff
}
.tag:not(body).is-warning {
    --cui-bg-opacity: 1;
    background-color: rgba(var(--cui-warning-rgb), var(--cui-bg-opacity)) !important;
    color: #fff
}

.tag:not(body).is-danger {
    --cui-bg-opacity: 1;
    background-color: rgba(var(--cui-danger-rgb), var(--cui-bg-opacity)) !important;
    color: #fff
}

.nav-link.active {
    background-color: var(--cui-btn-bg, transparent);
}

@include media-breakpoint-down(md) {
    .is-sm {
        display: grid;
    }
}

.sticky {
    position: sticky !important;
    position: -webkit-sticky !important;
    z-index: 2;
    top: -1px;
    background-color: whitesmoke;
}


.stick {
    display: flex;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
}

@media only screen and (max-width: 576px) {
    .rc-pagination-options {
        display: block !important;
    }
}

.border-left-primary {
    border-left: .25rem solid rgba(var(--cui-primary-rgb), 1) !important; 
}
.border-left-success {
    border-left: .25rem solid rgba(var(--cui-success-rgb), 1) !important; 
}
.border-left-info {
    border-left: .25rem solid rgba(var(--cui-info-rgb), 1) !important; 
}
.border-left-warning {
    border-left: .25rem solid rgba(var(--cui-warning-rgb), 1) !important; 
}


.field.is-grouped.is-grouped-multiline:last-child {
    margin-bottom: -0.75rem;
}

.field.is-grouped.is-grouped-multiline {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.field.is-grouped {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}