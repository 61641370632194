@media print {

  body {
    counter-reset: chapternum figurenum;
    margin: 0;
    color: #000;
    background-color: #fff;
    // overflow-x: hidden !important;
  }

}

@page {
  margin: 0;
}

#target_print {
  overflow: auto;
  max-height: 40rem;
}

.page-layout {
  font-size: 10px;
  font-weight: 600;

  .page {
    .page-kertas {
      width: 40px;
      height: 52px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      margin-right: 0.2rem;
      border-radius: 3px;
    }
  }
}

.box.print {
  padding-bottom: 0;
  color: unset;
}

.has-button {
  cursor: pointer;

  &:focus {
    color: whitesmoke;
  }

  i {
    color: yellowgreen;

    &:hover {
      color: rgba(92, 112, 128, 0.6);
    }
  }
}

body.print #print_target {
  // height: 100% !important;
}

.tabs.is-boxed {
  margin-bottom: 0;
}

.print-box {
  background-color: #cccccc;
  width: 100%;
}

.print #print_target {
  overflow: unset;
  background-color: unset;
}

.print .print-box {
  background-color: unset;
}

@media print {
  .print-box {
    background-color: unset;
    width: 100%;
  }

  #print_target {
    padding-top: unset !important;
  }
}

.print-box {
  padding: unset !important;
}

// @page {
//   margin: 10mm 10mm 10mm 10mm;  
//   size: A4 portrait;
//   size:  auto;   /* auto is the initial value */

//   font-size: 12pt;

//   #content, #page {
//     width: 100%;
//     margin: 0;
//     float: none;
//   }
// }

#print_target {
  width: 100%;
  // height: calc(100vh - 16vh);
  // overflow-y: auto;
  // overflow-x: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 4rem;

  .pagez {
    background-color: white;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 2px 2px 2px 2px #ccc, 2px 2px #ccc, 2px 2px #ccc;
  }

  .margin {
    border: 1px dashed;
    // height: 100%;
    border-color: rgba(0, 0, 0, 0.1);
  }

  @media print {
    .margin {
      border: unset;
    }

    .pagez {
      box-shadow: unset;
    }

    .page-breaks::after {
      // content: '\ Copyright ©2021 SIMADES VERSI 2.0.0';
      font-size: 10px;
    }
  }

  .logo-desa {
    width: 90px;
  }

  .img-logo {
    img {
      width: 90px;
      height: 114px;
    }
  }

  table.invoice-table  tr td {
    vertical-align: top;
  }
 

  .page-break.A4 {
    width: 210mm;
  }
  
  @media print {
    .page {
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }
    .page-break.A4 { 
      width: unset;
    }
    .A4.portrait  { 
      width: unset !important;
    }
    
  }
  
  @media print {
    table { page-break-after:auto }
    tr    { page-break-inside:auto; page-break-after:auto }
    td    { page-break-inside:auto; page-break-after:auto }
    thead { display:table-header-group }
    tfoot { display:table-footer-group }

  }


  @media screen, print {
    .page-breaks {
      font-size: 12pt;
      box-sizing: border-box;
      break-after: page;
      font-family: 'Arial Narrow', Arial, sans-serif;
      font-weight: normal;
      width: 210mm;
      // height: 333mm;
      color: #000;
      position: relative;
      page-break-before: auto;
    }

    .page-breaks.A4.portrait {
      width: 210mm;
      height: 297mm;
    }

    .page-breaks.A58.portrait {
      width: 80mm;
      font-size: 8pt !important;
      line-height: 10pt;
    }

    .page-breaks.A58 small {
      font-size: 9pt !important;
    }

    .page-breaks.A58 table {
      font-size: 12pt !important;
      line-height: 10pt;
    }

    .page-breaks.A58 .margin {
      width: 70mm;
      // height: 296mm;
    }

    .page-breaks.A4.landscape {
      height: 210mm;
      width: 296mm;
    }

    .page-breaks.folio.portrait {
      width: 216mm;
      height: 330mm;
    }

    .page-breaks.folio.landscape {
      height: 216mm;
      width: 330mm;
    }

    /* legal page */
    .page-breaks.leggal.portrait {
      width: 216mm;
      height: 356mm;
    }

    .page-breaks.leggal.landscape {
      height: 216mm;
      width: 356mm;
    }

    /* letter page */
    .page-breaks.leter.portrait {
      width: 216mm;
      height: 11in;
    }

    .page-breaks.leter.landscape {
      height: 216mm;
      width: 11in;
    }

   
    .margin-narrow {
      padding: 1.27cm;
    }

    .margin-custom {
      padding-top: 0.5cm;
      padding-left: 2.5cm;
      padding-right: 2cm;
      padding-bottom: 2cm;
    }

    .margin-normal {
      padding: 2.54cm;
    }

    .margin-wide {
      padding-top: 2.54cm;
      padding-left: 5.08cm;
      padding-right: 5.08cm;
      padding-bottom: 2.54cm;
    }

    /* for margin */

    .margin-left-30 {
      margin-left: 30px !important;
    }


    div > .font-small {
      line-height: 1;
      color: #a6a6a6;
      .page-breaks.A4.portrait {
        width: 210mm;
        height: 296mm;
      }
      font-size: 10pt;
    }

    .margin {
      line-height: 1.25;
    }

    // strong {
    //   color: #000;
    // }

    .border-bottom-solid {
      border-bottom: 2px solid #000;
      margin-bottom: 2px;
    }

    .hr-bold {
      border: 2px solid #000;
      height: 4px;
    }

    .is-label {
      min-width: 20rem;
    }

    .is-koma {
      margin-left: 5px;
      margin-right: 5px;
    }

    .is-justify {
      text-align: justify;
    }

    .margin-both-20 {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .margin-right-20 {
      margin-right: 20px;
    }

    .margin-right-40 {
      margin-right: 40px;
    }

    .margin-left-40 {
      margin-left: 40px;
    }

    .margin-left-20 {
      margin-left: 20px;
    }

    ul.indent > li {
      margin-left: 20px;
      list-style-type: disc;
    }

    .is-upper-alpha {
      list-style-type: upper-alpha;
      margin-left: 20px;
    }

    .is-unset-numbering {
      list-style-type: none;
    }

    .is-margin-left-20-minus {
      margin-left: -20px;
    }

    .is-lower-alpha {
      list-style-type: lower-alpha;
      margin-left: 20px;
    }

    .is-decimal {
      list-style-type: decimal;
      margin-left: 20px;
    }

    .is-no-decimal {
      list-style-type: none;
      margin-left: 0px;
    }

    .is-70-persen {
      width: 70%;
    }

    .is-30-persen {
      width: 30%;
    }
  }

  @media print {
    .logo-desa {
      width: 90px;
      margin-bottom: 4.5px;
    }
    .margin-narrow {
      padding: unset !important;
    }
  }
}

// .navbar-menu {
//   border-bottom: 1px solid #afadad;
// }
